import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { allMdx } from 'gatsby-plugin-mdx';

export default function PostLayout({ children, location }) {
    const data = useStaticQuery(graphql`
        query PostLayoutQuery {
            allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 6) {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            date
                            path
                            image
                            slug
                        }
                        slug
                    }
                }
            }
        }
    `);

    const posts = data.allMdx.edges.filter(({ node }) => !location.pathname.includes(node.slug));

    return (
        <Layout>
            <SEO
                title="Blog - Aguaman"
                description="Artículos del blog de Aguaman"
                pathname="/blog"
            />
            <section className="posts-blog-prop">
                {children}
                <h1>Para leer</h1>
                <div className="wrapper-blog-card">
                    {posts.map(({ node }) => (
                        <BlogPostCard
                            key={`post-${node.frontmatter.slug}}`}
                            title={node.frontmatter.title}
                            description={node.frontmatter.description}
                            image={node.frontmatter.image}
                            path={node.frontmatter.path}
                        />
                    ))}
                </div>
            </section>
        </Layout>
    );
}

function BlogPostCard(props) {
    const { title, description, image, path } = props;
    return (
        <article className="blog-card">
            <Link to={`/blog${path}`}>
                <div className="blog-card-heading">
                    <img src={image} />
                </div>
                <div className="blog-card-body">
                    <div className="blog-card-title">
                        <h4>{title}</h4>
                    </div>
                    <div className="blog-card-description">
                        <p>{description}</p>
                    </div>
                </div>
            </Link>
        </article>
    );
}
